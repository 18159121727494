import React from "react"
import Seo from "../components/seo"
import PMODashboard from "../components/Dashboard/pmo"

const IndexPage = props => {
  return (
    <>
      <Seo title="Dashboard" />
      <PMODashboard {...props} />
    </>
  )
}

export default IndexPage
